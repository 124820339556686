import React from "react";
import "./styles/History.css";

function History() {
  return (
    <div className="history-container">
      <p className='history-text'>
        Formed in 2018 with the focus of bringing rising talent in the world of melodic techno closer to us, wherever we may be. Our passion is delivering an intimate, immersive, and noteworthy atmosphere to the eyes and ears of the individual. 
      </p>
    </div>
  );
}

export default History;
