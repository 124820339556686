import React from 'react';
import './styles/Home.css'


let ssData = sessionStorage.getItem('mmvisitor')
class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            toggleModal: ssData ? false : true,
            upcomingEvents: []
            
    }
    }
    
    componentWillMount = () => {
            if (ssData) {
                this.setState({toggleModal: false})
            } 
        } 
      
    

    closeModal() {
        sessionStorage.setItem('mmvisitor', 'visited')
        this.setState({toggleModal: false})
        console.log(ssData)

    }

    render() {
        return (
        <div className='home-container'>
            <div className= {this.state.toggleModal ? 'fideles-modal' : 'none'}>
            
            <button className='close' onClick={() => this.closeModal()}>X</button>
            <div className='modal-text'>
            <p>Amidst these unprecedented times, the events on March 27th and April 17th have been cancelled.</p><br></br>
            <p>Practice social distancing. Stay <strong>healthy</strong> and <strong>safe</strong>.<br></br><br></br>We can't wait to have you back on the dancefloor.</p>
            </div>
            </div>
            <div className='text-container'>
                <p className='home-text'>Mind</p>
                <p className='home-text'>Over</p>
                <p className='home-text'>Matter</p>
            </div>

    </div>

        )}
}


export default Home