import React from "react";
import './styles/Contact.css'

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}
class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
      name: "",
      email: "",
      subject: "",
      message: ""
    };
  }



  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state })
    })
      .catch(error => console.log(error));
      window.location='/'
      alert("Thank you for your message. Look out for an e-mail from us.")
      console.log('SUCCESSFULLY SUBMITTED')
    e.preventDefault();
  };

  render() {
    return (
      <div className="contact-container">
        <p className='contact-questions'>Questions? Comments? Compliments? Concerns? <br></br><br></br>These things matter to us. <br></br>Please fill out the fields below and we'll get back to you.</p>
      
      <form 
      name='contact'
      className='contact-form' 
      onSubmit={this.handleSubmit}
      data-netlify="true" 
      data-netlify-honeypot="bot-field" >
          <label />
          <input
            placeholder="Full Name"
            value={this.state.name}
            name="name"
            required
            onChange={this.handleChange}
          />

<label />
          <input
            placeholder="Contact Details (Email/Phone)"
            value={this.state.contact_info}
            name="contact_info"
            required
            onChange={this.handleChange}
          />

          <label />
          <input
            placeholder="Subject"
            value={this.state.subject}
            name="subject"
            required
            onChange={this.handleChange}
          />

          <label />
          <textarea
            placeholder="What's on your Mind?/What's the Matter?"
            value={this.state.message}
            name="message"
            required
            onChange={this.handleChange}
          />

          <button type="submit">Submit</button>
        </form>
      
      </div>
    );
  }
}

export default Contact;
