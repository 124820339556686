const events = [
    {
        date: 'April 17 2020',
        imgurl: 'https://i.imgur.com/2RhXMxB.png',
        organizer: 'Mind/Matter',
        venue: '23 Meadow',
        artists: 'Edu Imbernon (Extended Set), Fur Coat, Hannes Bieger [Live]',
        supporting_artists: 'Orin',
        ticket_link: 'https://www.residentadvisor.net/events/1400186',
        rsvp_link: 'https://www.facebook.com/events/1180351455468534/',
        past_event: false 
    },
    {
        date: 'March 27nd 2020',
        imgurl: 'https://i.imgur.com/qQouwxB.png',
        organizer: 'Mind/Matter',
        venue: 'Bogart House',
        artists: 'Fideles (Extended Set)',
        supporting_artists: 'David Lindmer',
        ticket_link: 'https://www.residentadvisor.net/events/1388740',
        rsvp_link: 'https://www.facebook.com/events/239118250426514/',
        past_event: true  
    },

    {
        date: 'February 22nd 2020',
        imgurl: 'https://i.imgur.com/0NQafeM.jpg',
        organizer: 'Mind/Matter',
        venue: 'Knockdown Center',
        artists: 'Ae:ther[Live], Kevin De Vries, Moonwalk, Radio Slave, Woo York[Live]',
        supporting_artists: 'Adam Braiman',
        ticket_link: ['https://www.eventbrite.com/e/mind-matter-tickets-86608691929, https://www.residentadvisor.net/events/1364833'],
        rsvp_link: '',
        past_event: true
    },

    {
        date: 'November 29th 2019',
        imgurl: 'https://i.imgur.com/sqAFYxh.jpg',
        organizer: 'Mind/Matter X Techno Brooklyn',
        venue: 'Analog Brooklyn',
        artists: 'Agents Of Time [Extended DJ Set], Unseen., Amiti',
        supporting_artists: "",
        ticket_link: "",
        rsvp_link: '',
        past_event: true
    },
    {
        date: 'September 20th 2019',
        imgurl: 'https://i.imgur.com/6eQgHxm.jpg',
        organizer: 'Mind/Matter',
        venue: 'Bogart House',
        artists: 'Soel [Hybrid Set], ⌀ostil, Amiti',
        supporting_artists: "",
        ticket_link: "",
        rsvp_link: '',
        past_event: true
    },
    {
        date: 'June 7th 2019',
        imgurl: 'https://i.imgur.com/h9DcRHP.jpg',
        organizer: 'Mind/Matter',
        venue: 'Bogart House',
        artists: 'Keith Carnal, Hunter/Game, Adam Braiman',
        supporting_artists: "",
        ticket_link: "",
        rsvp_link: '',
        past_event: true
    },
    {
        date: 'March 9th 2019',
        imgurl: 'https://i.imgur.com/vJrYFyB.jpg',
        organizer: 'Mind/Matter',
        venue: 'The PaperBox',
        artists: 'Soel [Extended DJ Set], Adam Braiman, Tony Paniro',
        supporting_artists: "",
        ticket_link: "",
        rsvp_link: '',
        past_event: true
    }



]

export default events