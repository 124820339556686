import React from "react";
import "./styles/Merch.css";

class Merch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      merchList: []
    };
  }


  render() {
  
    return (
  
    <div className='shop'><h1 className='coming-soon'>Coming soon.</h1></div>
    )
  }
}

export default Merch;
