import React from "react";
import "./styles/Curate.css";
import "../forms/Form.css"

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }
class Curate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        name: "",
        email: "",
        phone: "",
        answer: ""
    };
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "curate", ...this.state })
    })
      .catch(error => console.log(error));
      window.location='/'
      alert("Thank you for your message. Look out for an e-mail/SMS from us.")
      console.log('SUCCESSFULLY SUBMITTED')
    e.preventDefault();
  };

  render() {
      const { name, email, phone, answer } = this.state;
    return (
      <div className="curate-container">
        <div className="curate-text-container">
          <p className="curate-text curate-melodic">
            "The world of melodic techno is always <br></br>
            <br></br> <span className="expand"> expanding</span>."
          </p>
          <br></br>
          <br></br>
          <p className="curate-text curate-strive">
            As we strive to bring you the talent you want to hear and see live,
            our minds are open to your input.
          </p>
          <p className="curate-text curate-improve">
            At the same time, we're always looking to improve the experience
            that we provide
          </p>
          <p className="curate-text curate-provided">
            Please use the provided fields to leave feedback.
          </p>
        </div>
        <div className="curate-form">

          
            <form 
            name='curate'
            className='actual-form'
            onSubmit={this.handleSubmit}
            data-netlify="true" 
            data-netlify-honeypot="bot-field" >
                
              <label/>
              <input
              type='text'
                placeholder="Full Name"
                value={name}
                name="name"
                required
                onChange={this.handleChange}
              />
              

              <label/>
              <input
                placeholder="E-Mail"
                type='email'
                value={email}
                name="email"
                required
                onChange={this.handleChange}
              />
              

              <label/>
              <input
                placeholder="Phone"
                type='text'
                value={phone}
                name="phone"
                required
                onChange={this.handleChange}
              />
              

              <label/>
              <textarea
                placeholder=" 
                Suggest artists, venues, or anything else. 
                We're listening."
                value={answer}
                name="answer"
                required
                onChange={this.handleChange}
              />
              

              <button className='curate-button' type="submit">Submit</button>
            </form>
          
          
        </div>
      </div>
    );
  }
}

export default Curate;
